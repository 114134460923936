.form-element-li-home {
  display: inline-block;
  padding-top: 1rem;
  vertical-align: top;
}

.form-floating {
  overflow: hidden;
}

label {
  text-shadow: none;
  border: none;
}

.li-home-form {
  h1 {
    font-size: 2.25rem;
  }
}

.password-div-li-home {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  position: relative;
}

#password-input-li-home {
  grid-area: 1 / 1 / span 1 / span 12;
}

.password-eye-button-li-home {
  grid-area: 1 / 12 / span 1 / span 1;
}

.submit-spinner-li-home {
  position: absolute;

  left: 26.5vw;
  top: 6vh;
}

.log-in-li-home {
  overflow-y: hidden;
}

#sign-up-button-li-home {
  margin-top: 1rem;
}

.password-form-li-home {
  padding-right: 1rem;
}

@media (min-width: 1200px) {
  .form-element-li-home {
    padding-top: 0.5rem;
  }

  .text-entry-li-home, .error-info-li-home {
    width: calc(32vw - 4rem);
  }

  .error-info-li-home-span {
    width: 100%;
  }

  .error-info-li-home, .error-info-li-home-span {
    font-size: 12px;
    border: none;
    text-shadow: none;
  }

  .input-divs-li-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    position: relative;

    margin-left: calc(6vw + 3px);
  }

  .top-username-div-li-home, .username-email-label-li-home {
    width: calc(2*(32vw - 4rem) + 4rem);
  }
}

@media (min-width: 815px) and (max-width: 1199px) {
  .app-body {
    overflow-y: auto;
  }

  .text-entry-li-home, .error {
    width: calc(45vw - 4rem);
  }

  #input-divs-li-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 4vw;
  }

  .username-div-li-home {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    left: calc(1rem + 1.2vw);
  }

  .username-email-form-li-home {
    overflow: hidden;
    padding-left: 0;
  }

  #username-email-error-li-home{
    margin-left: 1.5rem;
  }

  .password-form-li-home {
    padding-left: 0;
  }

  .username-input-li-home {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-left: 8px;
  }

  .log-in {
    height: auto;
  }

  #sign-up-button-li-home {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .username-label-li-home {
    margin-left: 8px;
  }

  .password-eye-button-li-home {
    right: 4rem;

    padding-right: calc(0.5rem + 1vw);
  }

  .password-form-li-home {
    padding-right: 0;
  }

  .password-div-li-home {
    width: auto;
  }

  .error-info-li-home-span {
    width: 100%;
  }
}

@media(max-width: 814px) {
  .password-form-li-home {
    padding-left: 0;
    margin-left: 0;
    width: calc(85vw - 2rem);
    overflow-x: hidden;
  }

  .text-entry-li-home, .error-su-home {
    width: calc(85vw - 2rem);
  }

  .form-control {
    width: calc(85vw - 2rem);
  }

  #input-divs-li-home {
    overflow-x: hidden;
  }

  .password-div-li-home, .username-div-li-home {
    width: calc(85vw - 2rem);
  }

  #password-input-li-home {
    padding-right: 1rem;
  }

  .username-email-form-li-home, .error-li-home {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .log-in {
    height: auto;
  }

  #sign-up-button-li-home {
    margin-bottom: 2rem;
  }
}

@media (max-height: 1000px) and (min-width: 1200px){
  .log-in-li-home {
    min-height: 210px;
  }
}