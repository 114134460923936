@import "../../node_modules/bootstrap";

$primary-color-1: #50c0e4;
$primary-color-2: #282c34;
$error-red: #ff9494;

.body-div {
  color: white;
  //overflow: auto;
}

div {
  overflow-y: auto;
}

.link-div {
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #F2F2F2;
}

::-webkit-scrollbar-thumb {
  background: #BDBDBD;
}

::-webkit-scrollbar-thumb:hover {
  background: #6E6E6E;
}