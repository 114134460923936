@use "../base";

.nav-header {
  background-color: base.$primary-color-2;

  display: flex;
  font-size: calc(10px + 1.5vmin);
  align-items: center;
  justify-content: space-between;

  min-height: 75px;

  height: 8.9vh;

  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 2rem;
    display: inline-flex;
    color: white;
  }

  .extra-links {
    padding-right: 1.5rem;
    width: auto;
  }

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: base.$primary-color-1;
  }

}

.home-text {
  color: white;
  font-size: calc(20px + 1.5vh);
  text-align: center;
}

.log-in-header, .profile-header {
  margin: 0 2rem 0.5rem 0.5rem;
}

.sign-up-header, .log-out-header {
  margin: 1rem 2rem 0 0.5rem;
}

@media (min-width: 1200px) {
  .nav-header {
    overflow: hidden;
    padding-bottom: 1rem;
  }
}

@media (max-width: 1199px) {
  #links {
    display: none;
    left: 0;
    text-align: left;
    margin-top: calc(1.25rem + 3vh);
    margin-bottom: 0;
  }

  .nav-header {
    position: relative;
    display: block;

    transition: height 0.2s linear;
    padding-bottom: 1rem;

    a {
      text-decoration: none;
      display: block;
    }

    a.icon {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .ham-menu {
    display: block;
  }

  .extra-links {
    display: block;
  }

  .home-text {
    --home-text-size: 30px;
    font-size: var(--home-text-size);
    position: absolute;
    top: 1rem;
  }

  ul {
    display: block;
  }

  .button-nav-header {
    display: inline;
    position: absolute;
    right: calc(1rem + 1vh);
    top: 1rem;
  }
}