@use "../base";

#sign-up-form, #email-sent-div {
  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  padding: 25px;

  margin: 5rem auto 0 auto;

  width: 50vw;
  max-width: 600px;
  min-width: 350px;
}

#email-sent-div {
  max-width: 1250px;
  width: 65vw;
}

.sign-up-body {
  height: 90vh;
}

#password-div, #confirm-password-div {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

#password-input, #confirm-password-input {
  grid-area: 1 / 1 / span 1 / span 12;
}

.form-element {
  margin: 2rem 0 0 0;
}

.text-entry {
  width: calc(50vw - 6rem);
  max-width: calc(600px - 4rem);
  min-width: calc(350px - 4rem);
}

label {
  color: black;
  font-size: 1rem;
  opacity: 75%;
}

.error-input {
  border: 2px solid red;
  border-radius: 6px;
}

.username {
  margin-top: 1.5rem;
}

.submit-button {
  margin-top: 2rem;

  position: relative;
  top: 0;
  bottom: 0;
  right: 0;

  height: 50px;
  width: 115px;

  border-radius: 25px;

  background-color: base.$primary-color-2;
  border-color: base.$primary-color-2;
  color: white;

  font-size: 20px;
}

#spinner-sign-up {
  margin-top: 2rem;
  margin-bottom: 0.75rem;
}

small {
  display: none;
}

.error {
  display: block;
  font-size: 0.9rem;
  padding-top: 0.4rem;

  color: base.$error-red;
}

.submit-button:hover {
  background-color: base.$primary-color-1;
  border-color: base.$primary-color-1;
}

.eye-button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  color: black;
  grid-area: 1 / 12 / span 1 / span 1;

  font-size: 1.5rem;

  position: relative;
  right: 1.5rem;
  top: 0.15rem;
}

#log-in-sign-up {
  padding-top: 1.1rem;
  padding-bottom: 0;
  font-size: 1.1rem;

  a {
    padding-bottom: 1rem;
    color: white;
  }

  a:hover {
    color: antiquewhite;
  }
}

#email-sent-div {
  text-align: center;

  a {
    padding-bottom: 1rem;
    color: white;
  }

  a:hover {
    color: antiquewhite;
  }
}