@use "../base";

.home-body {
  max-width: 100vw;
  width: 100vw;
  height: 90vh;
}

#description-div {
  margin-right: 0;
  margin-left: 0;
}

.container-element {
  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: base.$primary-color-1;
  }

}

.column {
  padding: 0;
}

.header-description {
  font-size: 1.6rem;
}

.link-description {
  font-size: calc(1rem + 0.29vw);
  margin: 0 0.5rem 0.74rem 0.5rem;
}

h1 {
  margin-bottom: 20px;
  text-decoration: underline;
}

@media (min-width: 1200px) {
  .container-element {
    margin: 5px 2rem 5px 2rem;
  }

  .description {
    height: 25vh;
  }

  .description-h1 {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .available-links {
    padding-top: 5.5%;
    margin: 0 2rem 0 2rem;
    height: 61vh;

    h1 {
      font-size: 2rem;
    }
  }

  .log-in {
    margin: 0 2rem 0.5rem 0.5rem;
    height: 23.25vh
  }

  .sign-up {
    margin: 1rem 2rem 0 0.5rem;
    height: 35.25vh;
  }
}

@media (max-width: 1199px) {
  .container-element, .available-links {
    margin: 5px 0.5rem;
    height: auto;
    padding-top: 7px;
  }

  .available-links {
    h1 {
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }

  .description {
    height: auto;
  }

  .description-h1 {
    font-size: calc(1.375rem + 2.03vw);
  }
}

@media (max-height: 875px) and (min-width: 1200px) {
  .available-links {
    min-height: calc(325px + 210px + 1rem);
  }
}

.sign-up {
  overflow-x: hidden;
}