#reset-password-form {
  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  padding: 25px 25px 10px 25px;

  margin: 5rem auto 0 auto;

  width: 50vw;
  max-width: 600px;
  min-width: 350px;
}

#reset-password-button, #spinner-rp {
  margin-bottom: 1rem;
}

#spinner-rp {
  margin-top: 2.75rem;
}

#reset-password-failure {
  margin-top: 1rem;
}