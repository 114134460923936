@use "../base";

#email-confirmed-div {
  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  padding: 25px;

  margin: 5rem auto 0 auto;

  width: 50vw;
  max-width: 600px;
  min-width: 350px;

  text-align: center;
  a {
    padding-bottom: 1rem;
    color: white;
  }

  a:hover {
    color: antiquewhite;
  }
}