.not-found-div {
  width: 50vw;
  max-width: 1000px;
  min-width: 350px;

  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  padding: 25px;

  margin: 5rem auto 0 auto;
  text-align: center;

  h2 {
    line-height: 1.75;
  }

  a {
    color: white;
  }

  a:hover {
    color: antiquewhite;
  }
}

.not-found-header {
  margin-bottom: 2rem;
}

.not-found-link {
  display: block;
}