@use "../base";

#log-in-form {
  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  padding: 25px 25px 10px 25px;

  margin: 5rem auto 0 auto;

  width: 50vw;
  max-width: 600px;
  min-width: 350px;
}

.link-div-li {
  text-align: center;
}

#extra-links-li {
  text-align: center;
  margin-top: 1.25rem;
  font-size: 1.1rem;

  .link-div {
    overflow-y: hidden;
  }

  a {
    padding-bottom: 1rem;
    color: white;
    height: auto;
  }

  a:hover {
    color: antiquewhite;
  }
}

#spinner-log-in-li {
  margin-top: 2rem;
  margin-bottom: 0.75rem;
}

.eye-button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  color: black;
  grid-area: 1 / 12 / span 1 / span 1;

  font-size: 1.5rem;

  position: relative;
  right: 1.5rem;
  top: 0.15rem;
}

#password-div {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

#error-entry-li {
  text-align: center;
}