@use "../base";

.password-form-su-home, .confirm-password-form-su-home {
  padding-left: 0;
}

.confirm-password-form-su-home {
  padding-left: 0.5rem;
}

.container-element {
  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: base.$primary-color-1;
  }
}

.form-element-su-home {
  display: inline-block;
  padding-top: 1rem;
  vertical-align: top;
}

#passwords-div-su-home {
  overflow: hidden;
}

.form-floating {
  overflow: hidden;
}

label {
  text-shadow: none;
  border: none;
}

.error-input {
  border: 2px solid red;
  border-radius: 6px;
}

#su-home-form {
  h1 {
    font-size: 2.25rem;
  }
}

.eye-button {
  grid-area: 1 / 12 / span 1 / span 1;
}

@media (min-width: 1200px) {
  #username-email-div-su-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: calc(2 * (32vw - 4rem) + 4rem);

    position: relative;

    margin-left: calc(6vw + 3px);
    left: 0;
    padding-left: 0;
  }

  .text-entry-su-home, .error-info-su-home {
    width: calc(32vw - 4rem);
  }

  #passwords-div-su-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: calc(2 * (32vw - 4rem) + 4rem);

    position: relative;

    margin-left: calc(6vw + 3px);
    left: 0;
    padding-left: 0;

    margin-top: 1rem;
  }

  .form-element-su-home, .error {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .password-input-su-home, .confirm-password-input-su-home {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .confirm-password-form-su-home {
    padding-left: 0;
  }

  .error-info-su-home {
    font-size: 12px;
    border: none;
    text-shadow: none;
  }

  .sign-up {
    overflow-y: hidden;
  }

  .email-sent-div-su-home {
    width: 68.5vw;
    max-width: 1500px;
  }

  .password-form-su-home {
    padding-left: 0;
  }

  .username-su-home, .email-su-home {
    padding-left: 0;
    padding-right: 0;
  }

  .email-su-home {
    padding-top: 1rem;
  }
}

@media (min-width: 815px) and (max-width: 1199px) {
  .username-su-home, .password-form-su-home {
    overflow-x: hidden;
  }

  #username-email-div-su-home, #passwords-div-su-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 4vw;
  }

  .username-su-home-floating, .email-su-home-floating {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  .app-body {
    overflow-y: auto;
  }

  .text-entry-su-home, .error {
    width: calc(45vw - 4rem);
  }

  .sign-up {
    height: auto;
  }

  #sign-up-button-su-home {
    margin-bottom: 2rem;
  }

  .username-su-home-floating, .password-div-su-home {
    left: calc(1rem + 1.2vw);
  }

  .email-su-home {
    padding-top: 1rem;
  }

  .confirm-password-div-su-home, .confirm-password-form-su-home {
    padding-left: 0;
    margin-left: 0;
  }

  .eye-button {
    right: 4rem;

    padding-right: calc(0.5rem + 1vw);
  }

  #email-sent-div-su-home {
    width: 95.5vw;
    margin-top: 1rem;
  }
}

@media (max-width: 814px) {
  .error-su-home {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .password-form-su-home {
    padding-top: 0.5rem;
  }

  .confirm-password-form-su-home {
    margin-left: 0;
    padding-left: 0;
  }

  .email-su-home {
    padding-top: 1rem;
    width: calc(85vw - 2rem);
    display: inline-block;
  }

  .text-entry-su-home {
    width: calc(85vw - 2rem);
  }

  .password-div-su-home, .confirm-password-div-su-home {
    width: calc(85vw - 2rem);
  }

  .password-input-su-home, .confirm-password-input-su-home {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .password-form-su-home {
    padding-left: 0;
  }

  .sign-up {
    height: auto;
  }

  #sign-up-button-su-home {
    margin-bottom: 2rem;
  }

  .error-info-su-home {
    width: calc(85vw - 2rem);
  }

  .email-sent-div-su-home {
    margin-top: 1rem;
  }
}

@media (max-height: 875px) and (min-width: 1200px) {
  .sign-up-home-div {
    min-height: 325px;
  }
}

#email-sent-div-su-home {
  background: none;
  border: none;
  border-radius: 0;
}