@use "base";

:root {
    --doc-height: 100%;
}

@media (min-width: 1200px) {
    .app {
        overflow-y: hidden;
    }
}

.app {
    text-align: center;
    height: var(#{--doc-height});
}

.app-body {
    height: var(#{--doc-height});

    min-height: 91.1vh;
    background: radial-gradient(base.$primary-color-1 50%, #177695) no-repeat fixed center;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);

    overflow-x: hidden;
}
