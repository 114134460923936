#forgot-password-form, #email-sent-div {
  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  padding: 25px 25px 10px 25px;

  margin: 5rem auto 0 auto;

  width: 50vw;
  max-width: 600px;
  min-width: 350px;
}

#email-sent-div {
  max-width: 1250px;
  width: 65vw;
}

#email-sent-div {
  text-align: center;

  a {
    padding-bottom: 1rem;
    color: white;
  }

  a:hover {
    color: antiquewhite;
  }
}

#forgot-password-button-fp {
  margin-bottom: 1rem;
}

#spinner-rp {
  padding-top: 1rem;
  margin-bottom: 3rem;
  margin-top: 1rem;
}