#email-input-rv {
  width: calc(50vw - 60px);
  max-width: 540px;
  min-width: 290px;
}

#resend-div {
  width: 50vw;
  max-width: 600px;
  min-width: 350px;

  background: rgba(0, 0, 0, 0.6);
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;

  padding: 25px;

  margin: 5rem auto 0 auto;
  text-align: center;
}

.error-rv {
  width: calc(50vw - 60px);
  max-width: 540px;
  min-width: 290px;

  padding-left: 0;
  padding-right: 0;
}

#resend-button {
  margin-top: 1.5rem;
}

#spinner-resend {
  margin-top: 2rem;
  margin-bottom: 0.75rem;
}